@import '../../Assets/Fonts/fonts.scss';
@import '../../Assets/Colors/colors.scss';

.headerBackground {
    background-color: $greenHeaderAndFooterColor;
    position: fixed;
    height: 50px;
    top: 0;
    z-index: 10;
}

.headerNames {
    color: white;
}

.headerRightItems {
    display: none;
}

.headerName {
    font-family: NowBold;
    font-weight: bold;
    font-size: 18px;
    padding-left: 15px;
    margin-top: 15px;
    white-space: nowrap;
    right: 10px;
    position: absolute;
}

.natashaReidName {
    margin-left: 25px;
}

.natashaReidImage {
    margin-top: -1px;
    position: absolute;
    height: 20px;
    width: 20px;
    -webkit-user-drag: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    pointer-events: none;
}

.headerMenuIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    margin-left: 10px;
    margin-top: -10px;
    left: 0px;
}

.headerMenuIcon:hover {
    cursor: pointer;
}

.closeMenuIcon {
    width: 19px;
    height: 19px;
    position: absolute;
    margin-left: 10px;
    margin-top: -5px;
    left: 0px;
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 2s;
    animation: fadeInOpacity 200ms ease-in-out 0s forwards;
}

.closeMenuIcon:hover {
    cursor: pointer;
}

@keyframes fadeInOpacity {
    from {
        transform: translateY(-50px);
    }

    to {
        transform: translateY(0px);
    }
}

.headerName a, .headerItems a {
    text-decoration: none;
    font-family: NowRegular;
    font-weight: 700;
    color: unset
}

.headerName:hover {
    color: rgb(190, 186, 186);
}

.headerBackground .headerSideBar {
    position: absolute;
    margin-top: 25px;
    z-index: 10;
    height: 400px;
    width: 100%;
    background-color: #323232;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 2s;
    animation: fadeInOpacity 200ms ease-in-out 0s forwards;
}

.headerItems {
    font-family: NowBold;
    font-size: 18px;
    margin-top: -23px;
    padding-bottom: 10px;
    white-space: nowrap;
}

.selectedHeaderItem {
    font-family: NowBold;
    font-size: 18px;
    margin-top: -23px;
    padding-bottom: 10px;
    white-space: nowrap;
    color: black;
    opacity: 0.4;
}

.headerItems:hover {
    cursor: pointer;
    color: rgb(190, 186, 186);
}

@media screen and (min-width: 600px) {
    .headerRightItems {
        display: block;
    }

    .headerMenuIcon {
        display: none;
    }

    .headerBackground .headerSideBar {
        display: none;
    }

    .headerName {
        left: 0px;
        margin-left: 30%;
        padding-top: 3px;
        position: unset;
    }
}

@media screen and (min-width: 800px) {
    .headerName {
        margin-left: 25px;
    }
}

@media screen and (min-width: 1100px) {
    .headerName {
        margin-left: 35px;
    }
}

@media screen and (min-width: 1300px) {
    .headerName {
        margin-left: 40px;
    }
}

@media screen and (min-width: 1500px) {
    .headerName {
        margin-left: 50px;
    }
}
