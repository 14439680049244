// MARK: - Used for naming items such as names, headings etc
@font-face {
    font-family: SanFranciscoDisplaySemibold;
    src: url(../../Assets/Fonts/SF-Pro-Display-Semibold.otf)
}

// MARK: - Used for naming items such as header items
@font-face {
    font-family: SanFranciscoDisplayRegular;
    src: url(../../Assets/Fonts/SF-Pro-Display-Regular.otf)
}

// MARK: - Used for naming items such as texts in paragraphs
@font-face {
    font-family: SanFranciscoTextRegular;
    src: url(../../Assets/Fonts/SF-Pro-Text-Regular.otf)
}

@font-face {
    font-family: NowThin;
    src: url(../../Assets/Fonts/Now/Now-Thin.otf);
}

@font-face {
    font-family: NowLight;
    src: url(../../Assets/Fonts/Now/Now-Light.otf);
}

@font-face {
    font-family: NowRegular;
    src: url(../../Assets/Fonts/Now/Now-Regular.otf);
}

@font-face {
    font-family: NowBold;
    src: url(../../Assets/Fonts/Now/Now-Bold.otf);
}
