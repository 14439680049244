@import '../../Assets/Fonts/fonts.scss';
@import '../../Assets/Colors/colors.scss';

.aboutUsBackground {
    padding: 10px;
}

.aboutUsBackground .optionBackground {
    background-color: $greyColor;
    margin-bottom: 10px;
    padding: 15px;
}

.aboutUsBackground .optionBackground .largeTitleBackground {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.aboutUsBackground .largeTitle {
    font-family: NowBold;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
    margin-top: 30px;
}

.aboutUsBackground .description, .aboutUsBackground .involvementDescription {
    font-family: NowRegular;
    font-size: 15px;
    line-height: 24px;
    text-align: justify;
    color: black;
    opacity: 0.5;
}

@media screen and (min-width: 600px) {
    .aboutUsBackground .description, .aboutUsBackground .involvementDescription {
        font-size: 16px;
    }

    .firstImage {
        margin-left: -3.5%;
    }

    .lastImage {
        padding-left: 3.5%;
    }

    .aboutUsBackground {
        margin-left: 3%;
        margin-right: 3%;
    }

    .aboutUsBackground .involvementDescription {
        margin-right: -10px;
    }
}

@media screen and (min-width: 700px) {
    .aboutUsBackground .largeTitle {
        font-size: 35px;
    }
}

@media screen and (min-width: 875px) {
    .aboutUsBackground .largeTitle {
        font-size: 45px;
    }
}

@media screen and (min-width: 900px) {
    .aboutUsBackground .description {
        font-size: 17px;
        margin-left: 10%;
        margin-right: 5%;
    }

    .aboutUsBackground .involvementDescription {
        font-size: 17px;
        margin-left: 10%;
        margin-right: 0px;
    }
}
