$greyColor: #F2F2F0;
$linkColor: #306EC3;
$headerBackgroundColor: #333333;
$greenHeaderAndFooterColor: #65793C;

$evenBackgroundColor: #BFBC6B;
$oddBackgroundColor: #D9D7A3;

:export {
  headerAndFooterColor: $greenHeaderAndFooterColor;
  evenColor: $evenBackgroundColor;
  oddColor: $oddBackgroundColor;
}
