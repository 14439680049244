@import '../../Assets/Fonts/fonts.scss';
@import '../../Assets/Colors/colors.scss';

.headerSideBar {
    position: absolute;
    margin-top: 23px;
    z-index: 10;
    height: 500px;
    width: 100%;
    background-color: $greenHeaderAndFooterColor;
    animation-name: fadeInOpacity;  
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 2s;
    animation: fadeInOpacity 500ms ease-in-out 0s forwards;
}

@keyframes fadeInOpacity {
    from {
        transform: translateY(-50px);
    }

    to {
        transform: translateY(0px);
    }
}

.sideMenuNames {
    font-family: NowBold;
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding-left: 10px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.sideMenuProjects:hover {
    cursor: pointer;
}

.selectedSideMenu {
    color: black;
    opacity: 0.3;
}

.sideMenuContacts:hover {
    cursor: pointer;
}

.sideMenuProjects a, .sideMenuContacts a {
    text-decoration: none;
    font-family: NowRegular;
    color: unset
}

.divider {
    background-color: gray;
    height: 1px;
}

@media screen and (min-width: 635px) {
    .headerSideBar {
        display: none;
    }
}
