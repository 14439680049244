@import '../../Assets/Fonts/fonts.scss';
@import '../../Assets/Colors/colors.scss';

.contactsBackground {
    padding: 10px;
}

.contactsBackground .optionBackground {
    background-color: $greyColor;
    margin-bottom: 10px;
    padding: 15px;
}

.contactsBackground .optionBackground .largeTitleBackground {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.contactsBackground .largeTitle {
    font-family: NowBold;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
    margin-top: 28px;
}

.contactsBackground .description {
    font-family: NowRegular;
    font-size: 15px;
    text-align: justify;
    line-height: 24px;
    color: black;
    opacity: 0.5;
}

@media screen and (min-width: 550px) {
    .contactsBackground .largeTitle {
        font-size: 45px;
    }
}

@media screen and (min-width: 600px) {
    .contactsBackground .description {
        font-size: 16px;
    }

    .contactsBackground {
        margin-left: 3%;
        margin-right: 3%;
    }
}

@media screen and (min-width: 800px) {
    .contactForm {
        margin-left: 5%;
        margin-right: 5%;
    }
}

@media screen and (min-width: 900px) {
    .contactsBackground .description {
        font-size: 17px;
        margin-left: 10%;
        margin-right: 10%;
    }
}

@media screen and (min-width: 1200px) {
    .contactForm {
        margin-left: 10%;
        margin-right: 10%;
    }
}

@media screen and (min-width: 1300px) {
    .contactForm {
        margin-left: 15%;
        margin-right: 15%;
    }
}
