@import '../../../Assets/Fonts/fonts.scss';

.title {
    font-family: NowBold;
    font-weight: bold;
    font-size: 25px;
}

.error {
    font-family: NowBold;
    font-weight: bold;
    font-size: 30px;
    color: red;
}

.description {
    font-family: NowRegular;
    font-size: 17px;
}