@import '../../Assets/Fonts/fonts.scss';
@import '../../Assets/Colors/colors.scss';

.footer {
    bottom: 0;
    background-color: $greenHeaderAndFooterColor;
    height: 45px;
}

.fixedFooter {
    bottom: 0;
    position: fixed;
    background-color: $greenHeaderAndFooterColor;
    height: 45px;
}

.footerItems {
    margin-top: 4px;
    padding: 10px;
    font-family: NowRegular;
    font-weight: 500;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.footerItems a {
    text-decoration: none;
    font-family: NowRegular;
    font-weight: 500;
    color: white;
}

.footerItems:hover {
    cursor: pointer;
    text-decoration: underline;
}
