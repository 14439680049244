@import '../../Assets/Fonts/fonts.scss';
@import '../../Assets/Colors/colors.scss';

.homeBackground {
    padding: 10px;
}

.homeBackground .optionBackground {
    background-color: $greyColor;
    margin-bottom: 10px;
    padding: 15px;
}

.homeBackground .optionBackground .largeTitleBackground, .optionBackground .subTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.homeBackground .largeTitle {
    font-family: NowBold;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
    margin-top: 30px;
}

.homeBackground .subTitle {
    font-family: NowBold;
    font-size: 18px;
    font-weight: bold;
}

.homeBackground .description {
    font-family: NowRegular;
    line-height: 24px;
    color: black;
    opacity: 0.5;
    font-size: 15px;
    text-align: justify;    
}

.homeBackground .otherDescription {
    font-family: NowRegular;
    font-size: 15px;
    text-align: justify;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: black;
    opacity: 0.5;
}

@media screen and (min-width: 550px) {
    .homeBackground .largeTitle {
        font-size: 45px;
    }
}

@media screen and (min-width: 600px) {
    .homeBackground .description, .homeBackground .otherDescription {
        font-size: 16px;
    }

    .homeBackground {
        margin-left: 3%;
        margin-right: 3%;
    }

    .homeBackground .subTitle {
        font-size: 20px;
        margin-left: 10%;
    }
}

@media screen and (min-width: 900px) {
    .homeBackground .description, .homeBackground .otherDescription {
        font-size: 17px;
        margin-left: 7%;
        margin-right: 7%;
    }
}
